import { cookieGet, cookieSet } from "./_helpers";
import { gsap } from "gsap";

export default class CookiesPopup {
    constructor() {
        this.cookiesPopup = document.querySelector('.cookies-popup');
        this.cookiesPopupClose = document.querySelector('.cookies-popup__close');

        this.cookiesPopup && this.init()
    }

    init = () => {
        cookieGet('allow_cookie') ? null : this.showCookiesPopup()
    }

    initEvents = () => {
        this.cookiesPopupClose.addEventListener('click', () => this.closeCookiesPopup(this.cookiesPopup))
    }

    showCookiesPopup() {
        this.initEvents()

        gsap.set(this.cookiesPopup, {
            x: '-150%',
        }).then(() => {
            this.cookiesPopup.classList.remove('hidden');

            gsap.to(this.cookiesPopup, {
                duration: 2,
                x: 0,
                ease: 'power3.inOut',
            })
        })
    }

    closeCookiesPopup(cookiesPopup) {
        gsap.to(cookiesPopup, {
            duration: 2,
            x: '-150%',
            opacity: 1,
            ease: 'power3.inOut',
        }).then(() => {
            cookiesPopup.classList.add('hidden');
            cookieSet('allow_cookie', 1, '/', 9999);
        })
    }
}
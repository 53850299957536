import { gsap } from 'gsap'
import { enableScroll, disableScroll } from "./_helpers";

export default class HeaderNavigation{
    constructor(navigationWrapper, openBtn, args = {}) {
        this.wrapper = navigationWrapper
        this.openBtn = openBtn
        this.closeBtn = this.wrapper.querySelector('.close-menu')
        this.header = document.querySelector('header')

        this.duration = args.hasOwnProperty('duration') ? args.duration : 1
        this.easing = args.hasOwnProperty('easing') ? args.easing : 'none'

        this.show = false

        this.init()
    }

    init = () => {
        this.openBtn?.addEventListener('click', this.handleOpenButton)
        this.closeBtn?.addEventListener('click', this.handleCloseButton)

        document.addEventListener('click', this.handleOutsideClick)

        window.addEventListener('scroll', this.handleScroll)
    }

    handleOpenButton = async (event) => {
        disableScroll()

        gsap.to(this.wrapper, {
            duration: this.duration,
            translateX: 0,
            ease: this.easing,
        }).then(() => this.show = true)
    }

    handleCloseButton = async (event) => {
        gsap.to(this.wrapper, {
            duration: this.duration,
            translateX: '100%',
            ease: this.easing,
        }).then(() => {
            this.show = false
            enableScroll()
        })
    }

    handleOutsideClick = async (event) => {
        if (this.show && !this.wrapper.contains(event.target)) {
            this.handleCloseButton()
        }
    }

    handleScroll = async (event) => {
        window.scrollY > 0 ? this.header.classList.add('scrolled') : this.header.classList.remove('scrolled')
    }
}
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class PageBanner {
    constructor() {
        this.element = document.querySelector('.page-banner')

        if(this.element) this.init()
    }

    init = () => {
        gsap.set(this.element, {
            backgroundPositionX: '100%',
        })

        this.handleScroll()
    }

    handleScroll = () => {
        gsap.to(this.element, {
            duration: 1,
            scrollTrigger: {
                trigger: this.element,
                start: 'top top',
                end: 'bottom top',
                scrub: 1.5,
            },
            backgroundPositionX: window.innerWidth > 991 ? '50%' : '75%',
        })
    }
}
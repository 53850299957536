import lazyLoadImages from "./components/lazyLoad";
import HeaderNavigation from "./components/Navigation";
import Alpine from "alpinejs";
import Swiper, { Navigation, Autoplay, Lazy } from "swiper";
import Plyr from "plyr";
import GoogleMap from "./components/GoogleMap";
import PageBanner from "./components/Banner";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/lazy";
import "plyr/dist/plyr.css";
import Animations from "./components/animations";
import CookiesPopup from "./components/CookiePopup";

lazyLoadImages();
document.body.addEventListener("force_lazyload", (e) => {
  lazyLoadImages();
});

document.addEventListener("DOMContentLoaded", () => {
  window.Alpine = Alpine;
  Alpine.start();

  gsap.registerPlugin(ScrollTrigger);

  const navigationWrapper = document.querySelector(".navigation");
  new HeaderNavigation(navigationWrapper, document.querySelector("#open-menu"), {
    duration: 0.9,
    easing: "power4.out",
  });

  new Plyr("#player", {});

  new GoogleMap();

  new PageBanner();

  new CookiesPopup();

  new Animations(
    ".fade-up",
    {
      opacity: 0,
      translateY: 120,
    },
    {
      opacity: 1,
      translateY: 0,
    }
  );

  new Animations(
    ".fade-left",
    {
      opacity: 0,
      translateX: 120,
    },
    {
      opacity: 1,
      translateX: 0,
    }
  );

  new Animations(
    ".fade-right",
    {
      opacity: 0,
      translateX: -120,
    },
    {
      opacity: 1,
      translateX: 0,
    }
  );

  const extendedSliders = [...document.querySelectorAll(".swiper-offer-extended")];
  const simpleSliders = [...document.querySelectorAll(".swiper-offer-simple")];
  const collaborationSliders = [...document.querySelectorAll(".swiper-collaboration")];

  extendedSliders.forEach((slider) => {
    new Swiper(slider, {
      ...defaultSliderOptions(slider),
      on: {
        init: ExtendedSliderSlideNumber,
        slideChangeTransitionEnd: ExtendedSliderSlideNumber,
      },
    });
  });

  simpleSliders.forEach((slider) => {
    new Swiper(slider, {
      ...defaultSliderOptions(slider),
    });
  });

  collaborationSliders.forEach((slider) => {
    new Swiper(slider, {
      modules: [Navigation, Autoplay, Lazy],
      slidesPerView: 1,
      spaceBetween: 16,
      loop: true,
      navigation: {
        nextEl: slider.querySelector(".swiper-button-next"),
        prevEl: slider.querySelector(".swiper-button-prev"),
      },
      breakpoints: {
        440: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 64,
        },
        1366: {
          slidesPerView: 6,
          spaceBetween: 115,
        },
      },
      autoplay: {
        delay: 1800,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
        checkInView: true,
        enabled: true,
      },
    });
  });

  const heroImage = document.querySelector(".hero-image");
  if (heroImage) {
    gsap.to(heroImage, {
      duration: 1,
      scrollTrigger: {
        trigger: heroImage.closest("section"),
        start: "top top",
        end: "bottom top",
        scrub: 1.5,
      },
      translateX: 80,
    });
  }

  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", (e) => {
      e.preventDefault();
    });
  });

  document.querySelector("#upload-file")?.addEventListener("change", (e) => {
    const file = e.target.files[0];
    document.querySelector(".upload-file").innerHTML = file.name;
  });
});

const ExtendedSliderSlideNumber = async ({ activeIndex, slides, navigation: { prevEl, nextEl } }) => {
  const nextExtendedSliderNumber = nextEl.querySelector(".navigation-number");
  const prevExtendedSliderNumber = prevEl.querySelector(".navigation-number");

  prevExtendedSliderNumber.innerText = parseSlideNumber(activeIndex === 0 ? slides.length : activeIndex);
  nextExtendedSliderNumber.innerText = parseSlideNumber(activeIndex === slides.length - 1 ? 1 : activeIndex + 2);
};

const parseSlideNumber = (number) => (number < 10 ? `0${number}` : number);

const defaultSliderOptions = (slider) => ({
  modules: [Navigation],
  slidesPerView: 1,
  spaceBetween: 48,
  navigation: {
    nextEl: slider.parentNode.querySelector(".swiper-button-next"),
    prevEl: slider.parentNode.querySelector(".swiper-button-prev"),
  },
});

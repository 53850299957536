import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default class Animations{
    constructor(elCLass, from = {}, to = {}) {
        this.elements = [...document.querySelectorAll(elCLass)]
        this.to = to
        this.from = from

        this.init()
    }

    init = () => {
        this.elements.forEach((element) => this.initAnimation(element))
    }

    initAnimation = async (element) => {
        ScrollTrigger.wat
        gsap.set(element, {
            ...this.from,
        })

        gsap.to(element, {
            ...this.to,
            scrollTrigger: {
                trigger: element.closest('section'),
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: 1.5,
                once: true,
            }
        })
    }
}